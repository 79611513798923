<template>
  <div style="height: 100%;">

    <l-map ref="map" class="map" id="theMap" :zoom="karte.zoom" :center="mapCenter" :options="karte.mapOptions">

      <l-tile-layer
      :name="karte.selectedTile.name"
      :url="karte.selectedTile.url"
      :attribution="karte.selectedTile.attribution"
      layer-type="base"
      />

      <!--Anzeige für einen Marker-->
      <l-marker v-if="markerData!=null" :lat-lng="markerData.latLng" :icon="getSymbolIcon()">
        <l-popup class="school-popup">
          <div class="address">
            <div>{{ markerData.street }}</div>
            <div>{{ markerData.zipcode }} {{ markerData.city }}</div>
          </div>
        </l-popup>
      </l-marker>

    </l-map>
  </div>
</template>

<script>
  import 'leaflet/dist/leaflet.css';
  import { latLng, icon } from 'leaflet';
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";

  export default {
    name: 'SingleMapView',
    components: {
      LTileLayer,
      LMarker,
      LPopup,
      LMap,
    },
    props: {
      height: {
        type: String,
        default: '700px',
      },
      addressObj: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        karte: {
          modus: false,
          zoom: 17,
          center: latLng(54.3232927, 10.1227652),
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: true,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   computed: {
    markerData(){
      if(this.addressObj!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'latitude') && this.addressObj.latitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'longitude') && this.addressObj.longitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'street') && this.addressObj.street!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'zipcode') && this.addressObj.zipcode!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'city') && this.addressObj.city!=null){

        var mapMarker = {lat:this.addressObj.latitude, long:this.addressObj.longitude, street:this.addressObj.street, zipcode:this.addressObj.zipcode, city:this.addressObj.city, latLng:latLng(this.addressObj.latitude,this.addressObj.longitude)}; 
        return mapMarker;
      }
      return null;
    },
    mapCenter(){
      if(this.markerData!=null){
        return this.markerData.latLng;
      }
      return latLng(54.3232927, 10.1227652);
    }
  },
  mounted() {
  },
  methods: {
    getSymbolIcon(){
      var customIcon = icon({
        iconUrl: '/assets/icons/marker.svg',
        shadowUrl: '',
        iconSize: [50, 50],/* size of the icon */
        iconAnchor: [20, 20], /* point of the icon which will correspond to marker's location */
        popupAnchor: [5, -21],
      })
      return customIcon;
    },
  },
};
</script>
<style lang="scss" scoped>

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

  .map{
    height:400px;
    border-radius: 15px;

    @media (max-width: 500px) {
      height:300px;
    }
  }

</style>

